import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Markdown from "../components/Markdown"
import Img from '../components/Img'

import meetTheArtist from "../cms/meet-the-artist"

export default () => (
  <Layout>
    <Head title="Meet the Artist" />

    <PageHeading text="Meet the Artist" centered />

    <div
      css={css`
        max-width: 500px;
        margin: 0 auto;
      `}
    >
      <Img
        src={meetTheArtist.portrait}
        alt="Portrait of Judith Glixon"
        css={css`
          border-radius: 8px;
        `}
        width={500}
      />
    </div>

    <Markdown
      css={css`
        text-align: center;
        font-size: 1rem;
        color: var(--text-light-gray);
        margin-top: .5rem;
        margin-bottom: 2rem;
      `}
      raw={meetTheArtist.portrait_credit}
    />

    <Markdown
      raw={meetTheArtist.text}
    />
  </Layout>
)
